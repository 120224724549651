import axios from '../axios'

export default {
  async listWarehousesForInspector({ commit }) {
    // зона проведения работ - запрос на инструмент в селекте и модалке (InstrumentsWorkZone)
    try {
      const { data } = await axios.get('inspector/warehouses')
      commit('setInspectorInstrument', data)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async listWarehousesForInspectorInModal({ commit }, payload) {
    // зона проведения работ - запрос на инструмент в селекте и модалке (InstrumentsWorkZone)
    console.log('listWarehousesForInspectorInModal', payload)
    try {
      const params = payload
        ? Object.keys(payload).reduce((acc, param, idx) => {
            if (payload[param]) acc += param + '=' + payload[param]
            if (idx !== Object.keys(payload).length - 1) acc += '&'
            return acc
          }, '?')
        : ''
      const res = await axios.get(`inspector/warehouses${params}`)
      if (payload && payload.search && payload.page === 1) commit('setInspectorInstrumentInModal', [])
      commit('setInspectorInstrumentInModal', res.data)
      return res.last_page
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async listWarehousesForAdminInModal({ commit }, payload) {
    try {
      // Формируем строку параметров запроса, фильтруя пустые значения
      const params = payload
        ? `?${new URLSearchParams(
            Object.fromEntries(Object.entries(payload).filter(([_, value]) => value)),
          ).toString()}`
        : ''
      // фильтр [has_work_zone_instruments]=1 дает отобразить только те инструменты, которых не добавили в зону работ
      const res = await axios.get(`admin/warehouses${params}`)

      // Если поиск и первая страница, очищаем предыдущее состояние
      if (payload?.search && payload.page === 1) {
        commit('deleteInspectorInstrumentInModal', [])
      }

      commit('setInspectorInstrumentInModal', res.data)
      return res.total_pages
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async downloadListInstrumentsWarehouses({ commit }, payload) {
    try {
      const response = await axios.get(`admin/warehouses${payload.args}`)
      commit('setInstruments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async warehouseShow({ commit }, warehouseId) {
    try {
      const res = await axios.get(`/admin/warehouses/${warehouseId}`)
      commit('setWarehouseShow', res)
    } catch (e) {
      commit('Notify/setError', 'При загрузке инструмента произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async downloadListInstruments({ commit }) {
    try {
      const data = await axios.get('/tasks/warehouse/list')
      commit('setInstruments', data)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async uploadNewInstrument({ commit }, model) {
    try {
      const data = new FormData()
      for (const [key, value] of Object.entries(model)) {
        if (value) {
          if (value?.value !== undefined) {
            data.set(key, String(value.value))
            continue
          }
          data.set(key, String(value))
        }
      }
      const response = await axios.post('/admin/warehouses', data)
      commit('setSelectedInstrument', {
        ...response,
      })
      commit('Notify/setSuccess', `Инструмент ${response.name} успешно добавлен`, { root: true })
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedInstrument({ state, commit }) {
    try {
      const id = state.selectedInstrument.id
      await axios.delete(`/admin/warehouses/${id}`)
      commit('Notify/setSuccess', 'Инструмент успешно удалён.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedInstrument({ state, commit }, payload) {
    try {
      const { id, model } = payload
      const data = new FormData()
      for (const [key, value] of Object.entries(model)) {
        if (value !== undefined) {
          data.set(key, String(value))
          continue
        }

        if (key === 'location_id' || key === 'module_id') {
          data.set(key, '')
          continue
        }

        data.set(key, String(value))
      }
      const response = await axios.put(`/admin/warehouses/${id}`, data)
      const updatedInstrument = {
        ...state.selectedInstrument,
        ...response,
      }
      commit('setSelectedInstrument', updatedInstrument)
      commit('Notify/setSuccess', 'Инструмент успешно обновлён.', { root: true })
      return true
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async unsetRFID({ state, commit }, payload) {
    try {
      // возможно расширение для массового удаления меток
      const response = await axios.post('/warehouses/unset-rfid', payload)

      commit('setRFIDById', response[0])
      const updatedInstrument = {
        ...state.selectedInstrument,
        ...response[0],
      }
      commit('setSelectedInstrument', updatedInstrument)
      return true
    } catch (e) {
      commit('Notify/setError', 'При очистки метки произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async addWarehouseRegistry({ commit }, payload) {
    try {
      const { module, warehouseId } = payload
      const response = await axios.post(`/module/${module.id}/registry`, { warehouse_id: warehouseId })
      commit('setRegistryForInstrument', { response, warehouseId, module })
      commit('setUpdateRegistryStatus', true)
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При добавлении модуля произошла ошибка', { root: true })
      throw new Error(error)
    }
  },
  async deleteWarehouseRegistry({ commit }, payload) {
    try {
      const { module, warehouseId } = payload
      await axios.put(`/module/${module.id}/registry`, { warehouse_id: warehouseId })
      commit('setRegistryForInstrument', { warehouseId, module })
      commit('setUpdateRegistryStatus', true)
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При удалении модуля произошла ошибка', { root: true })
      throw new Error(error)
    }
  },
}
