export default {
  taskList: state => state.taskList,
  taskListKanban: state => state.taskListKanban,
  isTasksLoading: state => state.isTasksLoading,
  isTaskDeleteLoading: state => state.isTaskDeleteLoading,
  currentPage: state => state.currentPage,
  count: state => state.count,
  total: state => state.total,
  totalPages: state => state.totalPages,
  perPage: state => state.perPage,
  kanbanCurrentPage: state => state.kanbanCurrentPage,
  kanbanTotal: state => state.kanbanTotal,
  isShowGantt: state => state.isShowGantt,
  filterResponsibleIsChecked: state => state.filterResponsibleIsChecked,
  equipmentList: state => state.equipmentList,
  taskVideoSources: state => state.taskVideoSources,
  saveMetrics: state => state.saveMetrics,
}
