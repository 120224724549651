import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  ganttList: {},
  subtaskGantt: {},
  tasksGantt: [],
  taskGantt: {},
  expiredTaskId: undefined,
  updatedGantt: [],
  dateDynamicTaskGantt: {},
  hasTasksForUpdate: false,
  taskWasUpdated: false,
  isGanttShow: false,
  taskGanttPause: undefined,
  plannedStart: null,
  plannedEnd: null,
  selectedGanttTask: undefined,
  updateDatesProjectWithTasks: '',
  currentProjectId: '',
  ganttColumns: [],
  hasFilteredTasks: false,
  criticalPathTasks: [],
  isCopyAction: false,
  resourcesAssigned: [],
  isRootMode: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
