export default {
  setIsGanttShow: (state, value) => {
    state.isGanttShow = value
  },
  setIsRootMode: (state, value) => {
    state.isRootMode = value
    localStorage.setItem('isGanttShow', value)
  },
  hasFilteredTasks: (state, value) => (state.hasFilteredTasks = value),
  setGanttColumns: (state, value) => (state.ganttColumns = value),
  currentProjectId: (state, value) => (state.currentProjectId = value),
  setGanttList: (state, value) => {
    const { list, isOnlyTasks, links } = value
    const tasks = Array.isArray(list) ? list : list?.data || []
    const existingLinks = list?.links || []

    const deletedTasks = JSON.parse(localStorage.getItem('deletedTasksFromGantt')) || []
    const deletedLinks = JSON.parse(localStorage.getItem('deletedLinksFromGantt')) || []

    const filteredTasks = tasks
      .filter(newTask => !deletedTasks.includes(newTask.id))
      .map(task => {
        const updatedTask = { ...task }
        if (updatedTask.fact_start_date) {
          updatedTask.start_date = updatedTask.fact_start_date
        }
        if (updatedTask.fact_end_date) {
          updatedTask.end_date = updatedTask.fact_end_date
        }
        return updatedTask
      })

    const filteredLinks = existingLinks.filter(link => !deletedLinks.includes(link.id))

    if (!state.ganttList) {
      state.ganttList = { data: [], links: [] }
    }

    if (isOnlyTasks) {
      if (links) {
        const existingTaskIds = state.ganttList.data.map(task => task.id)

        state.ganttList.data = state.ganttList.data.map(existingTask => {
          const updatedTask = filteredTasks.find(task => task.id === existingTask.id)
          return updatedTask ? { ...existingTask, ...updatedTask } : existingTask
        })

        state.ganttList.data = state.ganttList.data.filter(task => existingTaskIds.includes(task.id))
        state.ganttList.links = links || filteredLinks
      } else {
        state.ganttList.data = filteredTasks
        if (filteredLinks.length) {
          state.ganttList.links = filteredLinks
        }
      }
    } else {
      state.ganttList.data = filteredTasks
      state.ganttList.links = links || filteredLinks
    }
  },

  setIsCopyAction: (state, value) => (state.isCopyAction = value),
  setGanttSubtasksList: (state, value) => {
    value.forEach(newTask => {
      const updatedTask = { ...newTask }
      if (updatedTask.fact_start_date) {
        updatedTask.start_date = updatedTask.fact_start_date
      }
      if (updatedTask.fact_end_date) {
        updatedTask.end_date = updatedTask.fact_end_date
      }

      const existingTaskIndex = state.ganttList.data.findIndex(task => task.id === updatedTask.id)

      if (existingTaskIndex !== -1) {
        state.ganttList.data[existingTaskIndex] = {
          ...state.ganttList.data[existingTaskIndex],
          ...updatedTask,
        }
      } else {
        state.ganttList.data.push(updatedTask)
      }
    })
  },

  setPlannedStart: (state, value) => (state.plannedStart = value),
  setPlannedEnd: (state, value) => (state.plannedEnd = value),
  setGanttNewTask: (state, value) => {
    const task = {
      id: value.id,
      end_date: value.deadline,
      parent: value.state_id ? value.state_id : value.project_id,
      start_date: value.plan_date,
      text: value.name,
      responsible: value.responsible,
      expired: false,
      time_to_complete_sec: value.time_to_complete_sec,
      status: value.status,
      fact_end_date: value.fact_end_date,
      fact_start_date: value.fact_start_date,
      planned_start: value.planned_start,
      planned_end: value.planned_end,
    }
    state.ganttList.data.push(task)
  },
  setCopyTasks(state, value) {
    if (value.data) {
      state.ganttList.data.push(...value.data)
    }
    if (value.links) {
      state.ganttList.links.push(...value.links)
    }
  },
  setHasTasksForUpdate: (state, value) => {
    state.hasTasksForUpdate = value
  },
  setNewLink: (state, link) => {
    if (!link) return
    delete link.is_draft
    state.ganttList.links = state.ganttList.links.filter(l => l.source !== link.source || l.target !== link.target)
    state.ganttList.links.push(link)
  },

  setDeleteLink: (state, id) => {
    if (!id) return
    state.ganttList.links = state.ganttList.links.filter(el => el.id !== id)
  },
  setDeleteTask: (state, payload) => {
    if (!payload.taskId) return
    const { taskId, response } = payload
    state.ganttList.data = state.ganttList.data.filter(el => el.id !== taskId)
    response.forEach(newTask => {
      const taskIndex = state.ganttList.data.findIndex(el => el.id === newTask.id)
      if (taskIndex !== -1) {
        state.ganttList.data[taskIndex].status = newTask.status
        state.ganttList.data[taskIndex].start_date = new Date(newTask.start_date)
        state.ganttList.data[taskIndex].end_date = new Date(newTask.end_date)
        state.ganttList.data[taskIndex].fact_end_date = newTask.fact_end_date ? new Date(newTask.fact_end_date) : null
        state.ganttList.data[taskIndex].fact_start_date = newTask.fact_start_date
          ? new Date(newTask.fact_start_date)
          : null
      }
    })
  },
  setTaskGanttPause: (state, value) => (state.taskGanttPause = value),
  updateTaskGantt: (state, value) => {
    if (state.ganttList.data && value) {
      state.ganttList.data = state.ganttList.data.map(task => {
        if (task.id === value.id) {
          return {
            ...task,
            end_date: value.deadline,
            parent: value.state_id || value.project_id,
            start_date: value.plan_date,
            text: value.name,
            responsible: value.responsible,
            is_exists_warehouse: value.is_exists_warehouse,
            expired: value.expired,
            time_to_complete_sec: value.time_to_complete_sec,
            status: value.status,
            fact_end_date: value.fact_end_date ? new Date(value.fact_end_date) : null,
            fact_start_date: value.fact_start_date ? new Date(value.fact_start_date) : null,
            planned_start: value.planned_start ? new Date(value.planned_start) : null,
            planned_end: value.planned_end ? new Date(value.planned_end) : null,
          }
        }
        return task
      })
    }
  },

  setSelectedGantt: (state, value) => {
    state.selectedGanttTask = value
  },
  setTaskWasUpdated: (state, value) => {
    state.taskWasUpdated = value
  },
  updateDatesProjectWithTasks: (state, value) => (state.updateDatesProjectWithTasks = value),
}
