import axios from '../axios'
const transformData = dataArray => {
  return dataArray.reduce((acc, person) => {
    const taskIds = person.tasks_intersection.map(task => task.id)
    return acc.concat(taskIds)
  }, [])
}
const appendParams = (rootState, data, extraParams) => {
  if (!data) return
  const params = new URLSearchParams()
  const assignerResources = localStorage.getItem('assignedResources')
  let parsedResources = null
  if (assignerResources && extraParams) {
    try {
      parsedResources = JSON.parse(assignerResources)
      transformData(parsedResources).forEach((id, index) => {
        params.append(`intersection_assigned_tasks[${index}]`, id)
      })
    } catch (error) {
      console.error(error)
    }
  }
  data.projects.forEach((id, index) => {
    params.append(`projects[${index}]`, id)
  })
  // const teams = rootState.Auth.user.teams
  // teams.forEach((team, index) => {
  //   params.append(`teams_id[${index}]`, team.id)
  // })
  if (data.start_date && data.end_date) {
    params.append('start_date', data.start_date)
    params.append('end_date', data.end_date)
    params.append('with_parent_tasks', 1)
    params.append('filter[state]', 0)
  } else {
    params.append('filter[state]', 1)
  }
  if (data.gantt) {
    params.append('gantt', data.gantt)
  }

  return params
}
const updateGanttList = (state, newTasks) => {
  const updatedGanttList = [...state.ganttList.data]
  newTasks.forEach(newTask => {
    const existingTaskIndex = updatedGanttList.findIndex(task => task.id === newTask.id)

    if (existingTaskIndex !== -1) {
      updatedGanttList[existingTaskIndex] = {
        ...updatedGanttList[existingTaskIndex],
        end_date: newTask.end_date,
        planned_end: newTask.planned_end,
        planned_start: newTask.planned_start,
        start_date: newTask.start_date,
        fact_end_date: newTask.fact_end_date,
        fact_start_date: newTask.fact_start_date,
        status: newTask.status,
      }
    } else {
      updatedGanttList.push(newTask)
    }
  })
  return updatedGanttList
}
export default {
  async ganttList({ rootState, commit }, data) {
    try {
      if (!data && !data.projects) return
      const params = appendParams(rootState, data)
      const response = await axios.get(`/list/tasks/search?${params.toString()}`)
      const originalTasks = _.cloneDeep(response.tasks.data)

      if (params && params.toString().includes('with_parent_tasks')) {
        response.tasks.data.forEach(task => {
          const originalTask = originalTasks.find(t => t.id === task.id)
          if (originalTask) {
            task.start_to_filter = originalTask.start_date || null
            task.end_to_filter = originalTask.end_date || null
          }
        })
      }

      if (response) {
        commit('setGanttList', { list: response.tasks, isOnlyTasks: false })
      }
      if (data.isCanUpdate) commit('setTaskWasUpdated', true)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async networkScheduleList({ commit }) {
    try {
      const response = await axios.get(`/gantt/get_list_project_repair`)

      if (response && response.projects && response.projects.data) {
        const updatedData = response.projects.data.map(task => {
          const taskCopy = { ...task }
          delete taskCopy.$has_child
          return taskCopy
        })
        const tasks = {
          data: updatedData,
          links: [],
        }
        commit('setGanttList', { list: tasks, isOnlyTasks: false })
      }
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async updateGanttTree({ rootState, dispatch }, { node }) {
    if (!node.state_id) {
      const filteredDates = JSON.parse(localStorage.getItem('filterDatesForGantt'))
      const data = {
        projects: [rootState.Project.selectedProjectId],
        gantt: 1,
        isCanUpdate: true,
      }
      if (filteredDates && filteredDates.start_date && filteredDates.end_date) {
        data.start_date = filteredDates.start_date
        data.end_date = filteredDates.end_date
      }
      dispatch('ganttList', data)
    }
    if (node.state_id || node.parent) {
      dispatch('ganttListTaskBranch', node.id)
    }
  },
  async ganttSubtasksList({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.get(`/task/${data.parent_id}/subtasks/list?${params.toString()}`)
      commit('setGanttSubtasksList', response)
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async ganttCommit({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/draft/commit?${params.toString()}`)
      if (response) {
        commit('setHasTasksForUpdate', false)
        commit('setGanttList', { list: response.tasks, isOnlyTasks: true, links: response.links })
      }
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async ganttUpdate({ dispatch, commit }, payload) {
    try {
      await axios.post('/gantt/draft/update', payload)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (error) {
      console.error(error)
    }
  },

  async ganttAllTaskUpdate({ dispatch, commit }, payload) {
    try {
      await axios.post('/gantt/drafts/create', payload)
      commit('setHasTasksForUpdate', true)
      commit('updateDatesProjectWithTasks', '')
      return true
    } catch (error) {
      console.error(error)
    }
  },
  async ganttRootTaskUpdate({ state, dispatch, commit }, payload) {
    try {
      const res = await axios.post('/update_dates_for_task', payload)
      const taskId = res.id
      const updatedTasks = state.ganttList.data.map(task => {
        if (task.id === taskId) {
          const updatedTask = {
            ...task,
            fact_end_date: res.fact_end_date ? new Date(res.fact_end_date) : task.fact_end_date,
            fact_start_date: res.fact_start_date ? new Date(res.fact_start_date) : task.fact_start_date,
            planned_start: res.planned_start ? new Date(res.planned_start) : task.planned_start,
            planned_end: res.planned_end ? new Date(res.planned_end) : task.planned_end,
            end_date: res.deadline || task.end_date,
            start_date: res.plan_date || task.start_date,
          }

          if (updatedTask.fact_start_date) {
            updatedTask.start_date = updatedTask.fact_start_date
          }
          if (updatedTask.fact_end_date) {
            updatedTask.end_date = updatedTask.fact_end_date
          }

          return updatedTask
        }
        return task
      })
      commit('setGanttList', { list: updatedTasks, isOnlyTasks: false })
      commit('setTaskWasUpdated', true)
      return res
    } catch (error) {
      console.error(error)
    }
  },
  async ganttExtra({ commit }, payload) {
    try {
      await axios.post('/gantt/calc_plan_date_by_task_draft', payload)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (error) {
      console.error(error)
    }
  },
  async ganttFixPlan({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/set-planned-date?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: true })
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async ganttRollback({ rootState, commit }, data) {
    try {
      const params = appendParams(rootState, data)
      const response = await axios.post(`/gantt/draft/rollback?${params.toString()}`)
      commit('setGanttList', { list: response.tasks, isOnlyTasks: true, links: response.links })
      return response
    } catch (error) {
      console.error('rollback', error)
    }
  },

  async createLink({ commit }, payload) {
    try {
      const res = await axios.post('/gantt/links', payload)
      commit('setNewLink', res)
      commit('setHasTasksForUpdate', true)
      return res
    } catch (error) {
      console.error(error)
    }
  },

  async deletedLink({ state, commit }, id) {
    try {
      await axios.delete(`gantt/links/${id}?is_draft=${1}`)
      commit('setDeleteLink', id)
      commit('setHasTasksForUpdate', true)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },

  async ganttAddTask({ dispatch, commit }, data) {
    try {
      const response = await axios.post('tasks', data)
      await dispatch('ganttListTaskBranch', response.id)
      commit('setGanttNewTask', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },

  async ganttDeletedTask({ commit }, taskId) {
    try {
      const response = await axios.delete(`tasks/${taskId}?is_draft=${1}`)
      commit('setHasTasksForUpdate', true)
      const payload = { taskId, response }
      commit('setDeleteTask', payload)
      return response
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async ganttListTaskBranch({ rootState, state, commit }, id) {
    if (!id) return
    const params = new URLSearchParams()
    const teams = rootState.Auth.user.teams
    teams.forEach((team, index) => {
      params.append(`teams_id[${index}]`, team.id)
    })
    try {
      const response = await axios.get(`/get_list_task_branch?task_id=${id}&gantt=1&${params.toString()}`)
      if (response && response.length > 0) {
        const updatedGanttList = updateGanttList(state, response)
        commit('setGanttList', { list: updatedGanttList, isOnlyTasks: true })
        commit('setTaskWasUpdated', true)
      }
      return response
    } catch (error) {
      console.error(error)
    }
  },
  async ganttPastTask({ commit }, payload) {
    const { copyTaskId, type, isProject, pastTaskId, projectId } = payload

    try {
      const res = await axios.post(`gantt/${copyTaskId}/clone`, {
        with_subtask: !!type,
        project_id: projectId,
        state_id: isProject ? undefined : pastTaskId,
        is_draft: 1,
      })

      if (res) {
        commit('setCopyTasks', res.tasks)
        commit('setHasTasksForUpdate', true)
      }
      return res
    } catch (e) {
      commit('Notify/setError', 'При копировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async planHistory({ commit }, payload) {
    try {
      await axios.post('repairs/metrics/set_plan_history', payload)
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении фиксированного плана произошла ошибка. Попробуйте позднее', {
        root: true,
      })
    }
  },
}
