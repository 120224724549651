export default {
  setUser(state, data) {
    localStorage.setItem('user', JSON.stringify(data))

    state.path = data.roles.length ? data.roles[0].name : ''
    state.user = data
    const fullName = `${data.last_name} ${data.first_name}`
    document.title = `RITM - ${fullName}`
  },
  setUserRoles: (state, data) => {
    if (data) {
      state.userRoles = data
      localStorage.setItem('userRoles', JSON.stringify(data))
    } else state.userRoles = JSON.parse(localStorage.getItem('userRoles')) || []
  },
  clearUser(state) {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('path')
    localStorage.removeItem('accesses')
    localStorage.removeItem('routes')
    localStorage.removeItem('userRoles')
    state.path = null
    state.user = null
    document.title = 'RITM'
  },
  setErrors(state, payload) {
    state.errors = payload
  },
  setFirstLogin(state, value) {
    state.isFirstLogin = value
  },
}
