import axios from './../axios'

export default {
  async userAuth({ commit }) {
    try {
      const response = await axios.get('/users/auth')
      if (response.user.roles) {
        commit('setUserAuth', response.user)
        commit('setUserPermissions', response.user.roles)
        commit('Auth/setUser', response.user, { root: true })
        commit('Auth/setUserRoles', response.user.roles, { root: true })
      }
      return response
    } catch (e) {
      console.log('e', e)
    }
  },
  async userShow({ commit, rootGetters }, id) {
    try {
      if (!rootGetters['Auth/user'].roles.length) return

      const user = await axios.get(`/admin/users/${id}`)
      const rolesSet = new Map()
      if (user) {
        user?.teams?.forEach(team => {
          team.roles?.forEach(role => {
            if (!rolesSet.has(role.id)) {
              rolesSet.set(role.id, { display_name: role.display_name, id: role.id })
            }
          })
        })
        user.roles = Array.from(rolesSet.values())
      }

      commit('setUser', user)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка пользователей произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async downloadListUsers({ commit }, payload) {
    try {
      const response = await axios.get(`/admin/users${payload.args}`)
      const list = response.data
      commit('setUsers', list)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      return true
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка пользователей произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  clearListUsers({ commit }) {
    commit('setUsers', [])
    commit('setPerPage', undefined)
    commit('setTotalRows', undefined)
  },

  async uploadNewUser({ commit, rootGetters }, model) {
    try {
      let path = rootGetters['Auth/path'].toLowerCase()
      if (rootGetters['Auth/userAccesses'].user.includes('create')) path = 'admin'
      const response = await axios.post(`/${path}/users`, model)

      commit('Notify/setSuccess', `Пользователь ${response.full_name} успешно добавлен`, { root: true })
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedUser({ commit, rootGetters }, payload) {
    try {
      const { id, model } = payload
      const isAuthUser = rootGetters['Auth/user'].id === id
      let path = rootGetters['Auth/path'].toLowerCase()
      if (rootGetters['Auth/userAccesses'].user.includes('update')) path = 'admin'
      const response = await axios.put(`/${path}/users/${id}`, model)
      if (isAuthUser) {
        const roles = response.teams.map(el => el.roles).flat()
        commit('setUserPermissions', roles)
        commit('Auth/setUserRoles', roles, { root: true })
      }
      commit('setUser', response)
      return response
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async deletePhotosUser({ commit }, model) {
    try {
      const response = await axios.post('/users/photos/delete', model)
      commit('setUser', response)
      commit('Notify/setSuccess', 'Фото успешно удалены', { root: true })
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async deletePassesUser({ state, commit }, model) {
    try {
      const response = await axios.post('/admin/delete/passes', model)
      if (response) {
        const updatedUsers = state.users.map(user => {
          if (user.id === response.user.id) {
            return {
              ...user,
              passes: [],
            }
          }
          return user
        })
        commit('setUsers', updatedUsers)
        commit('Notify/setSuccess', 'Карты доступа успешно удалены', { root: true })
      }
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedUser({ state, rootGetters, commit }) {
    try {
      const id = state.selectedUser.id
      let path = rootGetters['Auth/path'].toLowerCase()
      if (rootGetters['Auth/userAccesses'].user.includes('delete')) path = 'admin'
      // const path = rootGetters['Auth/path'].toLowerCase()
      await axios.delete(`/${path}/users/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async changePassword({ commit }, payload) {
    const { id, model } = payload
    try {
      await axios.put(`/users/${id}/password`, model)
      commit('Auth/setFirstLogin', undefined, { root: true })
    } catch (e) {
      commit('Notify/setError', 'При изменении пароля произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
